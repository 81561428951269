import axios from 'axios'
// const api = 'http://farmersba.hengshunits.com' // 宝安
const api = 'https://farmers.hengshunits.com/' // 大鹏

// 上传照片
export function upload (param) {
  const params = new FormData()
  params.append('type', 'image')
  params.append('image', param.file)
  return axios.post(api + '/api/v1/uploads', params).then(res => {
    return res.data.data.url
  })
}

export function uploadXlsx (param) {
  const params = new FormData()
  params.append('excel', param.file)
  return axios.post(api + '/admin/v1/seller_excel_import', params)
}
export function uploadXlsxList (param) {
  const params = new FormData()
  params.append('excel', param.file)
  return axios.post(api + '/admin/v1/shop_excel_import', params)
}
// 导入摊位
export function uploadXlsxImport (param) {
  const params = new FormData()
  params.append('excel', param.file)
  return axios.post(api + '/admin/v1/booth_excel_import', params)
}

import request from './request'

//  省份
export const provinceCityArea = () => {
  return request({
    url: 'api/v1/provinceCityArea',
    method: 'GET'
  })
}
//  城市
export const getcity = provinceCityArea => {
  return request({
    url: `api/v1/provinceCityArea/${provinceCityArea}`
  })
}

// 市场经营类型
export const operation = () => {
  return request({
    url: 'admin/v1/search_operation'
  })
}
// 市场筛选
export const getmarket = () => {
  return request({
    url: 'admin/v1/search_market'
  })
}
// 分区
export const GETsearchSegmentation = id => {
  return request({
    url: 'admin/v1/search_segmentation?mk_id=' + id
  })
}
// 摊位类型
export const GETsearchBoothType = () => {
  return request({
    url: 'admin/v1/search_booth_type'
  })
}
// 摊位
export const GETsearchbooth = id => {
  return request({
    url: 'admin/v1/search_booth?seg_id=' + id
  })
}
// 经营状态
export const GETsearchStatus = () => {
  return request({
    url: 'admin/v1/search_forms'
  })
}

// 市场列表
export const marketlist = ({
  page,
  limit,
  mkid,
  provinceid,
  marketoperationtype
}) => {
  return request({
    url: `admin/v1/market_list?page=${page}&limit=${limit}&mk_id=${mkid}&province_id=${provinceid}&market_operation_type=${marketoperationtype}`
  })
}

// 市场分区列表
export const segmentation = ({ page, limit, id }) => {
  return request({
    url: 'admin/v1/market_segmentation_list',
    params: {
      page,
      limit,
      mk_id: id
    }
  })
}

// 市场分区列表
export const searchstation = () => {
  return request({
    url: 'admin/v1/search_station'
  })
}

export const marketSegmentationPage = () => {
  return request({
    url: 'admin/v1/market_segmentation_page'
  })
}

// 市场列表列表
export function marketAddpage (id) {
  return request({
    url: 'admin/v1/market_addpage',
    params: {
      id
    }
  })
}

// 市场信息添加或者修改 提交接口
export function marketInfoadd (data) {
  return request({
    url: 'admin/v1/market_infoadd',
    method: 'POST',
    data
  })
}

// 市场人员列表
export function marketManageList (params) {
  return request({
    url: 'admin/v1/market_manage_list',
    params
  })
}
// 市场分区页面（添加或者修改）的提交
export const POSTmarketManageList = data => {
  return request({
    url: 'admin/v1/market_segmentation_submit',
    method: 'POST',
    data
  })
}
// 市场管理人员添加页面  文化程度数据[education_data]  岗位数据[market_station_data] 市场数据[market_information_data]
export const GETmarketManagePage = () => {
  return request({
    url: 'admin/v1/market_manage_page',
    method: 'GET'
  })
}
// 市场管理人员添加提交
export const POSTmarketManagePage = data => {
  return request({
    url: 'admin/v1/market_manage_add',
    method: 'POST',
    data
  })
}
// 市场管理人员修改提交
export const POSTmarketManageUpdate = data => {
  return request({
    url: 'admin/v1/market_manage_update',
    method: 'POST',
    data
  })
}

///admin/v1/user_service
// 市场管理人员修改提交
export const user_service = data => {
  return request({
    url: 'admin/v1/user_service',
    method: 'GET',
    params: data
  })
}
// /admin/v1/user_service/:id

// 市场列表列表
export function market_addpage (data, id) {
  return request({
    url: 'admin/v1/user_service/' + id,
    method: 'PUT',
    data
  })
}

// 市场排名列表
export function rankingTabel (data) {
  return request({
    url: '/admin/v1/market_ranking',
    params: data
  })
}
// 市场排名列表
export function putRanking (id, data) {
  return request({
    url: '/admin/v1/market_ranking/' + id,
    method: 'PUT',
    data
  })
}
// 市场排名列表
export function postRanking (data) {
  return request({
    url: '/admin/v1/market_ranking',
    method: 'POST',
    data
  })
}

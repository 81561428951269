import request from './request'

// 商品分类列表 表格数据
export const GETsuppliers = ({ page, limit }) => {
  return request({
    url: 'admin/v1/suppliers?limit=' + limit + '&page=' + page,
    method: 'GET'
  })
}
// 供应商分类
export const GETsupplierTypes = () => {
  return request({
    url: 'admin/v1/supplier_types',
    method: 'GET'
  })
}
// 供应商分类
export const POSTsuppliers = data => {
  return request({
    url: 'admin/v1/suppliers',
    method: 'POST',
    data
  })
}
// 供应商分类
export const POSTsuppliertypes = data => {
  return request({
    url: 'admin/v1/supplier_types',
    method: 'POST',
    data
  })
}
// 更新供应商分类
export const PATCHsuppliertypes = ({ name, id }) => {
  return request({
    url: `admin/v1/supplier_types/${id}?name=` + name,
    method: 'PATCH'
  })
}
// 更新供应商分类
export const PATCHsuppliers = ({ data, id }) => {
  return request({
    url: `admin/v1/suppliers/${id}`,
    method: 'PATCH',
    data
  })
}
// 根据商户ID获取商户商品列表
export const getProductBySellerId = ({ marketid = '', sellerid = '' }) => {
  return request({
    url: `admin/v1/getProductBySellerId?market_id=${marketid}&seller_id=${sellerid}`,
    method: 'GET'
  })
}

<template>
  <div>
    <div>
      <el-button type="primary" @click="aad = true">新 增</el-button>
      <el-table :data="tableDatas" border style="width: 100%;margin-top:20px">
        <el-table-column prop="id" label="供应商ID"> </el-table-column>
        <el-table-column prop="name" label="供应商名称"> </el-table-column>
        <el-table-column prop="contact_name" label="负责人姓名">
        </el-table-column>
        <el-table-column prop="contact_phone" label="负责人电话">
        </el-table-column>
        <el-table-column label="营业执照">
          <template slot-scope="scope">
            <img
              :src="scope.row.business_license"
              style="width:130px;height:50px"
              alt=""
              @click="$refs.img.previewImage(scope.row.business_license)"
            />
          </template>
        </el-table-column>
        <el-table-column label="许可证">
          <template slot-scope="scope">
            <img
              :src="scope.row.permit"
              style="width:130px;height:50px"
              alt=""
              @click="$refs.img.previewImage(scope.row.permit)"
            />
          </template>
        </el-table-column>
        <el-table-column label="供应商分类姓名">
          <template slot-scope="scope">
            <span>{{ scope.row.supplier_type.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="商户姓名" prop="seller.name">
          <!-- <template slot-scope="scope">
            <span>{{scope.row.}}</span>
          </template> -->
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="modification(scope.row)"
              >编 辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="footers">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          current-page.sync="1"
          :page-sizes="[10, 20, 30]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="addtitle"
      @close="addtitletype"
      :visible.sync="aad"
      width="40%"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <div style="display:flex;justify-content: space-evenly; ">
          <div>
            <el-form-item label="所属商户" prop="seller_id">
              <el-select v-model="ruleForm.seller_id" placeholder="请选择">
                <el-option
                  v-for="item in Form.sellerid.data"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="供应商名称" prop="name">
              <el-input
                style="width:217px"
                placeholder="请输入供应商名称"
                v-model="ruleForm.name"
              ></el-input>
            </el-form-item>
            <el-form-item label="供应商类型" prop="supplier_type_id">
              <el-select
                v-model="ruleForm.supplier_type_id"
                placeholder="请选择供应商类型"
              >
                <el-option
                  v-for="item in Form.suppliertypeid"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <el-button @click="innerVisible = true">供应商</el-button>
            </el-form-item>
            <el-form-item label="负责人" prop="contact_name">
              <el-input
                style="width:217px"
                placeholder="请输入负责人名称"
                v-model="ruleForm.contact_name"
              ></el-input>
            </el-form-item>
            <el-form-item label="负责人电话" prop="contact_phone">
              <el-input
                style="width:217px"
                placeholder="请输入负责人电话"
                v-model="ruleForm.contact_phone"
              ></el-input>
            </el-form-item>
            <el-form-item label="营业执照">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :http-request="uploadSectionFile1"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="ruleForm.business_license"
                  :src="ruleForm.business_license"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="其他证照">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :http-request="uploadSectionFile2"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="ruleForm.other_license"
                  :src="ruleForm.other_license"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="省份" prop="province_id">
              <el-select
                v-model="ruleForm.province_id"
                placeholder="请选择省份"
              >
                <el-option
                  v-for="item in Form.provinceid"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  @click.native="clickprovinceid"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="城市" prop="city_id">
              <el-select v-model="ruleForm.city_id" placeholder="请选择城市">
                <el-option
                  v-for="item in Form.cityid.children"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  @click.native="clickcityid"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="区县" prop="area_id">
              <el-select v-model="ruleForm.area_id" placeholder="请选择区县">
                <el-option
                  v-for="item in Form.areaid.children"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  @click.native="clickareaid"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="街道" prop="street_id">
              <el-select v-model="ruleForm.street_id" placeholder="请选择街道">
                <el-option
                  v-for="item in Form.streetid.children"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="详细地址" prop="address">
              <el-input
                style="width:217px"
                placeholder="请输入详细地址"
                v-model="ruleForm.address"
              ></el-input>
            </el-form-item>
            <el-form-item label="许可证">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :http-request="uploadSectionFile3"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="ruleForm.permit"
                  :src="ruleForm.permit"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item class="bottom">
              <el-button type="primary" @click="submitForm('ruleForm')"
                >立即创建</el-button
              >
            </el-form-item>
          </div>
        </div>
      </el-form>
      <el-dialog
        width="30%"
        title="供应商分类列表"
        :visible.sync="innerVisible"
        append-to-body
        center
      >
        <el-table
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
          :data="Form.suppliertypeid"
          border
          style="width: 100%"
          max-height="240px"
        >
          <el-table-column prop="id" label="供应商分类ID"> </el-table-column>
          <el-table-column prop="name" label="供应商分类名称">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" @click="addsuppliertypeid(scope.row)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top:20px;text-align:right">
          <el-button type="primary" @click="Visible = true">添 加</el-button>
        </div>
        <el-dialog
          width="30%"
          title="添加供应商分类"
          :visible.sync="Visible"
          append-to-body
          center
        >
          <el-form>
            <el-form-item label="供应商分类名称" prop="typeName">
              <el-input
                v-model="typeName"
                style="width:217px"
                placeholder="请输入供应商分类名称"
              ></el-input>
            </el-form-item>
            <div style="text-align:right">
              <el-button @click="sbumitSupplierTypes" type="primary"
                >确 认</el-button
              >
            </div>
          </el-form>
        </el-dialog>
      </el-dialog>
    </el-dialog>
    <DialogImg v-show="0" ref="img" />
  </div>
</template>

<script>
import {
  GETsuppliers,
  GETsupplierTypes,
  POSTsuppliers,
  POSTsuppliertypes,
  PATCHsuppliertypes,
  PATCHsuppliers
} from '../../../api/purchaseList'
import { GETsearch } from '../../../api/manage'
import { provinceCityArea, getcity } from '../../../api/layout'
import { upload } from '../../../utile/upload'
export default {
  naem: 'supplier',
  data () {
    return {
      tableDatas: [], // 表格数据
      aad: false,
      innerVisible: false,
      Visible: false,
      addtitle: '新增供应商',
      typeName: '', // 供应商分类名称
      typeNameID: '', // 供应商分类id
      tableDataID: '', // 编辑ID
      Form: {
        sellerid: '', // 所属商户
        provinceid: '', // 省份
        cityid: '', // 城市
        areaid: '', // 区县
        streetid: '', // 街道
        suppliertypeid: '' // 供应商类型
      },
      ruleForm: {
        seller_id: '', // 所属商户ID
        name: '', // 供应商名称
        contact_name: '', // 负责人姓名
        contact_phone: '', // 负责人电话
        supplier_type_id: '', // 供应商类型
        province_id: '', // 省
        city_id: '', // 市
        area_id: '', // 区
        street_id: '', // 街道
        address: '', // 详细地址
        business_license: '', // 营业执照
        permit: '', // 许可证
        other_license: '' // 其他证照
      },
      rules: {
        name: [
          { required: true, message: '请输入供应商名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        contact_name: [
          { required: true, message: '请输入负责人姓名', trigger: 'blur' }
        ],
        contact_phone: [
          { required: true, message: '请输入负责人电话', trigger: 'blur' }
        ],
        supplier_type_id: [
          { required: true, message: '请选择供应商类型', trigger: 'blur' }
        ],
        province_id: [
          { required: true, message: '请选择省份', trigger: 'blur' }
        ],
        city_id: [{ required: true, message: '请选择城市', trigger: 'blur' }],
        area_id: [{ required: true, message: '请选择区县', trigger: 'blur' }],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        seller_id: [
          { required: true, message: '请选择所属商户', trigger: 'blur' }
        ]
      },
      total: 0,
      params: {
        page: 1,
        limit: 10
      }
    }
  },
  methods: {
    GET_search () {
      GETsearch({ page: '', limit: 10000, search: '' }).then(res => {
        this.Form.sellerid = res.data
      })
      // 省份
      provinceCityArea().then(res => {
        this.Form.provinceid = res.data
      })
    },
    // 供应商类型
    GET_supplierTypes () {
      GETsupplierTypes().then(res => {
        this.Form.suppliertypeid = res.data.data
      })
    },
    // 城市
    clickprovinceid () {
      this.ruleForm.city_id = ''
      this.ruleForm.area_id = ''
      this.ruleForm.street_id = ''
      getcity(this.ruleForm.province_id).then(res => {
        this.Form.cityid = res.data
      })
    },
    // 区县
    clickcityid () {
      this.ruleForm.area_id = ''
      this.ruleForm.street_id = ''
      getcity(this.ruleForm.city_id).then(res => {
        this.Form.areaid = res.data
      })
    },
    // 街道
    clickareaid () {
      this.ruleForm.street_id = ''
      getcity(this.ruleForm.area_id).then(res => {
        this.Form.streetid = res.data
      })
    },
    // 表格数据
    GET_suppliers () {
      GETsuppliers({ page: this.params.page, limit: this.params.limit }).then(
        res => {
          this.tableDatas = res.data.data
          this.total = res.data.total
          // console.log(this.tableDatas)
        }
      )
    },
    // 分页
    handleSizeChange (val) {
      this.params.page = 1
      this.params.limit = val
      this.GET_suppliers()
    },
    handleCurrentChange (val) {
      this.params.page = val
      this.GET_suppliers()
    },
    // 上传图片的校验
    beforeAvatarUpload (file) {
      const isJPG1 = file.type === 'image/png'
      const isJPG2 = file.type === 'image/jpeg'
      const isJPG3 = file.type === 'image/bmp'
      const isJPG4 = file.type === 'image/gif'
      const img = [isJPG1, isJPG2, isJPG3, isJPG4]
      const some = img.some(item => {
        return item
      })
      if (!some) {
        this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
      }
      return some
    },
    // 获取真实的图片路径 营业执照
    uploadSectionFile1 (param) {
      // this.ruleForm.business_license = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.ruleForm.business_license = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    uploadSectionFile2 (param) {
      // this.ruleForm.other_license = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.ruleForm.other_license = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    uploadSectionFile3 (param) {
      // this.ruleForm.permit = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.ruleForm.permit = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.addtitle === '编辑供应商') {
            PATCHsuppliers({ data: this.ruleForm, id: this.tableDataID })
              .then(res => {
                if (res.code === 200) {
                  this.aad = false
                  this.ruleForm = {
                    seller_id: '', // 所属商户ID
                    name: '', // 供应商名称
                    contact_name: '', // 负责人姓名
                    contact_phone: '', // 负责人电话
                    supplier_type_id: '', // 供应商类型
                    province_id: '', // 省
                    city_id: '', // 市
                    area_id: '', // 区
                    street_id: '', // 街道
                    address: '', // 详细地址
                    business_license: '', // 营业执照
                    permit: '', // 许可证
                    other_license: '' // 其他证照
                  }
                  this.GET_suppliers(this.page, this.limit)
                }
              })
              .catch(err => {
                this.$message.error(`${err.msg}`)
              })
          } else {
            POSTsuppliers(this.ruleForm)
              .then(res => {
                if (res.code === 200) {
                  this.aad = false
                  this.ruleForm = {
                    seller_id: '', // 所属商户ID
                    name: '', // 供应商名称
                    contact_name: '', // 负责人姓名
                    contact_phone: '', // 负责人电话
                    supplier_type_id: '', // 供应商类型
                    province_id: '', // 省
                    city_id: '', // 市
                    area_id: '', // 区
                    street_id: '', // 街道
                    address: '', // 详细地址
                    business_license: '', // 营业执照
                    permit: '', // 许可证
                    other_license: '' // 其他证照
                  }
                  this.GET_suppliers(this.page, this.limit)
                }
              })
              .catch(err => {
                this.$message.error(`${err.msg}`)
              })
          }
        } else {
          return false
        }
      })
    },
    // 新增供应商分类
    sbumitSupplierTypes () {
      if (this.typeName === '') {
        this.$message.error('请填写名称')
        return
      }
      if (this.typeNameID) {
        PATCHsuppliertypes({ name: this.typeName, id: this.typeNameID }).then(
          res => {
            if (res.code === 200) {
              this.Visible = false
              this.typeName = ''
              this.typeNameID = ''
              this.$message.success(`${res.data}`)
              this.GET_supplierTypes()
            } else {
              this.$message.error(`${res.msg}`)
            }
          }
        )
      } else {
        POSTsuppliertypes({ name: this.typeName }).then(res => {
          if (res.code === 200) {
            this.Visible = false
            this.typeName = ''
            this.$message.success(`${res.data}`)
            this.GET_supplierTypes()
          } else {
            this.$message.error(`${res.msg}`)
          }
        })
      }
    },
    addsuppliertypeid (row) {
      this.typeName = row.name
      this.typeNameID = row.id
      this.Visible = true
    },
    async modification (row) {
      this.aad = true
      this.tableDataID = row.id
      this.addtitle = '编辑供应商'
      this.ruleForm.seller_id = row.seller_id
      this.ruleForm.name = row.name
      this.ruleForm.contact_name = row.contact_name
      this.ruleForm.supplier_type_id = row.supplier_type_id
      this.ruleForm.address = row.address
      this.ruleForm.business_license = row.business_license
      this.ruleForm.permit = row.permit
      this.ruleForm.other_license = row.other_license
      this.ruleForm.contact_phone = row.contact_phone
      this.ruleForm.province_id = row.province_id
      await this.clickprovinceid()
      this.ruleForm.city_id = row.city_id
      await this.clickcityid()
      this.ruleForm.area_id = row.area_id
      await this.clickareaid()
      this.ruleForm.street_id = row.street_id
    },
    addtitletype () {
      this.addtitle = '新增供应商'
      this.ruleForm.seller_id = ''
      this.ruleForm.name = ''
      this.ruleForm.contact_name = ''
      this.ruleForm.supplier_type_id = ''
      this.ruleForm.address = ''
      this.ruleForm.business_license = ''
      this.ruleForm.permit = ''
      this.ruleForm.other_license = ''
      this.ruleForm.contact_phone = ''
      this.ruleForm.province_id = ''
      this.ruleForm.city_id = ''
      this.ruleForm.area_id = ''
      this.ruleForm.street_id = ''
    }
  },
  mounted () {
    this.GET_search()
    this.GET_suppliers()
    this.GET_supplierTypes()
  }
}
</script>

<style lang="less" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.bottom {
  margin-top: 100px;
}
.footers {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

import request from './request'

// 经营户基本信息管理   表格
export const GETsearch = ({ page, limit, search }) => {
  return request({
    url: `admin/v1/seller_list?page=${page}&limit=${limit}&search=${search}`,
    method: 'GET'
  })
}
// 经营户基本信息管理   新增
export const POSTsubmit = data => {
  return request({
    url: 'admin/v1/seller_submit',
    method: 'POST',
    data
  })
}

// 经营户经营信息列表
export const GETsellerShopList = data => {
  return request({
    url: 'admin/v1/seller_shop_list',
    method: 'GET',
    params: data
  })
}

//  商户经营页面（店铺）
export const GETsellerShoPpage = () => {
  return request({
    url: 'admin/v1/seller_shop_page',
    method: 'GET'
  })
}
//  商户经营添加/修改 提交
export const POSTsellerShopSubmit = data => {
  return request({
    url: 'admin/v1/seller_shop_submit',
    method: 'POST',
    data
  })
}
//  商户审核
export const GetsellerCheck = data => {
  return request({
    url: 'admin/v1/seller_shop_check',
    method: 'POST',
    data
  })
}

// 经营户详情
export const Getseller = id => {
  return request({
    url: 'admin/v1/seller_shop/' + id
  })
}

//获取单位类型
export const getRoles = () => {
  return request({
    url: '/admin/v1/getRoles',
    method: 'GET'
  })
}
//  商户经营页面（店铺）
export const getPatrolCompany = data => {
  return request({
    url: '/admin/v1/getPatrolCompany',
    params: data
  })
}
//巡查单位列表
export const patrolCompanys = data => {
  return request({
    url: '/admin/v1/patrolCompanys',
    params: data
  })
}
//新增巡查单位
export const post_patrolCompanys = data => {
  return request({
    url: '/admin/v1/patrolCompanys',
    method: 'POST',
    data
  })
}
//编辑巡查单位
export const post_patrolCompanysid = (data, id) => {
  return request({
    url: '/admin/v1/patrolCompanys/' + id,
    method: 'PUT',
    data
  })
}
/**
 * 巡查人员模块
 */
// 巡查人员列表查询
export const get_patrols = data => {
  return request({
    url: '/admin/v1/patrols',
    params: data
  })
}
//新增巡查人员列

export const post_patrols = data => {
  return request({
    url: '/admin/v1/patrols',
    method: 'POST',
    data
  })
}

export const put_patrols = (data, id) => {
  return request({
    url: `/admin/v1/patrols/${id}`,
    method: 'PUT',
    data
  })
}

//
export const get_PatrolCompany = data => {
  return request({
    url: '/admin/v1/getPatrolCompany',
    params: data
  })
}

export const Inspec_tionRecords = data => {
  return request({
    url: '/admin/v1/InspectionRecords',
    params: data
  })
}

///  巡查记录明细表

export const get_InRecords = id => {
  return request({
    url: `/admin/v1/InspectionRecords/${id}`,
    method: 'GET'
  })
}
//巡查项目列表
export const get_Categorys = data => {
  return request({
    url: '/admin/v1/InspectionCategorys',
    params: data
  })
}
//巡查项目列表
export const get_InspectionCategorys = data => {
  return request({
    url: '/admin/v1/getInspectionCategorys',
    params: data
  })
}

// 新增巡查项目

export const post_Inspection = data => {
  return request({
    url: '/admin/v1/InspectionInformations',
    method: 'POST',
    data
  })
}
//查看巡查项目

export const Inspec_Records = id => {
  return request({
    url: `/admin/v1/InspectionInformations/${id}`,
    method: 'GET'
  })
}

export const update_Records = (data, id) => {
  return request({
    url: `/admin/v1/InspectionInformations/${id}`,
    method: 'PUT',
    data
  })
}

export const delete_Records = id => {
  return request({
    url: `/admin/v1/InspectionRecords/${id}`,
    method: 'DELETE'
  })
}
